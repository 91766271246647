import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useProjectCode from "../../utils/CustomHook/useProjectCode";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { deleteDir, deleteFile, deleteProject } from "../../api/TreeAPI/common";

export default function DeleteConfirmModal({
  isOpenConfirmModal,
  handleClose,
  node,
  onActionSuccess,
}) {
  const projectCode = useProjectCode();
  let navigate = useNavigate();

  const [nodeType, setNodeType] = React.useState("");

  const checkNodeType = () => {
    if (!node) return;
    if (node?.isDir && node?.path === "/") {
      setNodeType("project");
    } else if (node?.isDir && node?.path !== "/") {
      setNodeType("folder");
    } else {
      setNodeType("file");
    }
  };

  React.useEffect(() => {
    checkNodeType();
  }, [node]);

  const handleDeleteProject = async () => {
    let res = await deleteProject(projectCode, node);
    if (res && res.status === 200) {
      toast.success("Delete project successfully");
      handleClose();
      navigate("/");
    } else {
      toast.error("Delete project failed");
      handleClose();
    }
  };

  const handleDeleteFile = async () => {
    let res = await deleteFile(projectCode, node);
    if (res.status === 200) {
      toast.success(`File deleted successfully`);
      onActionSuccess();
      handleClose();
    } else {
      toast.error("Delete file failed");
      handleClose();
    }
  };

  const handleDeleteFolder = async () => {
    let res = await deleteDir(projectCode, node);
    if (res.status === 200) {
      toast.success(`Folder deleted successfully`);
      onActionSuccess();
      handleClose();
    } else {
      toast.error("Delete folder failed");
      handleClose();
    }
  };

  return (
    <div>
      <Dialog
        open={isOpenConfirmModal}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Delete Project</DialogTitle>
        <DialogContent className="border-b border-[#e5e7eb]">
          <DialogContentText id="alert-dialog-description" className="pt-5">
            {`Are you sure you want to delete this ${nodeType}`}{" "}
            <span className="font-bold">{node?.name} </span>?
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            padding: "18px 20px",
          }}
        >
          <Button
            onClick={handleClose}
            sx={{
              color: "#0009",
              padding: "12px 16px",
              border: "1px solid #80808033",
              borderRadius: "0.375rem",
              "&:hover": {
                backgroundColor: "rgb(249 250 251)",
              },
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              nodeType === "project"
                ? handleDeleteProject()
                : nodeType === "folder"
                ? handleDeleteFolder()
                : handleDeleteFile();
            }}
            sx={{
              color: "red",
              padding: "12px 16px",
              border: "1px solid #80808033",
              borderRadius: "0.375rem",
              "&:hover": {
                backgroundColor: "rgb(249 250 251)",
              },
            }}
            autoFocus
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
