import React, { useEffect, useState } from "react";
import Editor, { DiffEditor, useMonaco, loader } from "@monaco-editor/react";
import { toast } from "react-toastify";
import { convertLang } from "../../../utils/func/convertLang";
import { usePrompt } from "../../../utils/CustomHook/usePrompt";
import useProjectCode from "../../../utils/CustomHook/useProjectCode";
import { getFileTypeFromName } from "../../../utils/func/filename";
import Preview from "../Preview/Preview";
import { useViewport } from "../../../utils/CustomHook/useViewport";
import { editFile, getFileContent } from "../../../api/fileAPI";
import { formatJSON } from "../../../utils/func/formatJSON";
import EditorHeader from "./EditorHeader";
import { MEDIA_FILE } from "../../../utils/func/common";
import LoadingDialog from "../../../components/Modal/LoadingDialog";

const FileEditor = ({
  filePath,
  onContentSaved,
  contentChanged,
  setContentChanged,
  createNewFile,
  previewUrl,
  previewMarkdown,
  onOpenExplorer,
}) => {
  const projectCode = useProjectCode();

  useEffect(() => {
    if (filePath) {
      let type = getFileTypeFromName(filePath).toLowerCase();
      if (!MEDIA_FILE.includes(type)) {
        loadFileContent(filePath);
      }
    }
  }, [filePath]);

  const [language, setLanguage] = useState("javascript");
  const [fileType, setFileType] = useState("javascript");
  const [content, setContent] = useState("");
  const [isSaving, setIsSaving] = useState(false);
  const [showEditor, setShowEditor] = useState(false);
  const [originalContent, setOriginalContent] = useState("");

  const [previewWidth, setPreviewWidth] = useState("600px");
  const [hidePreview, setHidePreview] = useState(true);
  const [contract, setContract] = useState(false);

  const [loading, setLoading] = useState(false);
  const [viewOption, setViewOption] = useState("code");

  useEffect(() => {
    setContentChanged(content != originalContent);
  }, [content, originalContent]);

  const loadFileContent = async (filePath) => {
    if (!filePath || !projectCode) {
      setOriginalContent("");
      setLanguage("");
      setFileType("");
      setContent("");
      return;
    }
    setLoading(true);
    let fileType = getFileTypeFromName(filePath).toLowerCase();
    setLanguage(fileType);
    const res = await getFileContent(projectCode, filePath);
    if (res) {
      if (fileType === "json") {
        setContent(JSON.stringify(res.content || {}));
        setOriginalContent(JSON.stringify(res.content || {}));
      } else {
        setContent(res.content || "");
        setOriginalContent(res.content || "");
      }
      setShowEditor(true);
    } else {
      toast.error("Couldn't find file");
    }
    setLoading(false);
  };

  const handleFileEdit = async (e) => {
    if (!filePath) return;
    setIsSaving(true);
    let res = await editFile(projectCode, content, filePath);
    if (res) {
      toast.success("Edit successfully", {
        position: "top-center",
      });
      setOriginalContent(content);
      if (onContentSaved) onContentSaved(content);
    } else {
      toast.error("Failed to edit file");
    }
    setIsSaving(false);
  };

  const handleContentChange = (e) => {
    setContent(e);
  };

  if (!isSaving || !contentChanged) {
    document.onkeydown = (e) => {
      let isSKey = e.key === "s" || e.key === "S";
      let isMetaKey = e.metaKey;
      if ((e.ctrlKey && isSKey) || (isMetaKey && isSKey)) {
        e.preventDefault();
        handleFileEdit();
      }
    };
  }

  usePrompt("Changes you made may not be saved.", contentChanged === true);

  const handleOpenEditor = () => {
    setHidePreview(true);
    setViewOption("code");
  };

  const handleSplit = () => {
    setHidePreview(false);
    setViewOption("split");
  };

  const handleOpenPreview = () => {
    setHidePreview(false);
    setPreviewWidth("100%");
    setContract(true);
    setViewOption("preview");
  };

  useEffect(() => {
    setFileType(getFileTypeFromName(filePath).toLowerCase());
    if (MEDIA_FILE.includes(getFileTypeFromName(filePath).toLowerCase())) {
      handleOpenPreview();
    } else {
      handleOpenEditor();
    }
  }, [filePath]);

  const [openExplorer, setOpenExplorer] = useState(true);

  const { width } = useViewport();

  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    if (width < 620) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [width]);

  let renderDesktopClass = `h-full ${
    viewOption === "preview" ? "hidden" : ""
  } ${hidePreview ? "w-full" : "w-2/4"}`;
  let renderMobileClass = `w-full ${
    viewOption === "preview" ? "hidden" : ""
  }  ${hidePreview ? "h-full" : "h-2/4"}`;

  return (
    <div className="w-full h-full bg-slate-400 flex flex-col">
      <EditorHeader
        openExplorer={openExplorer}
        setOpenExplorer={setOpenExplorer}
        width={width}
        filePath={filePath}
        fileType={fileType}
        viewOption={viewOption}
        handleOpenEditor={handleOpenEditor}
        handleSplit={handleSplit}
        handleOpenPreview={handleOpenPreview}
        isSaving={isSaving}
        contentChanged={contentChanged}
        handleFileEdit={handleFileEdit}
        onOpenExplorer={onOpenExplorer}
      />

      {/* Editor */}
      <div
        className={`grow flex ${
          isMobile && viewOption != "code" ? "flex-col" : ""
        }`}
      >
        <div
          className={` ${
            isMobile ? `${renderMobileClass}` : `${renderDesktopClass}`
          }`}
        >
          {!showEditor && (
            <div className="w-full h-full grid place-items-center text-white text-2xl opacity-70">
              No file selected
            </div>
          )}
          {showEditor && (
            <Editor
              className="w-full"
              height="100%"
              defaultLanguage={convertLang(language)}
              defaultValue=""
              onChange={handleContentChange}
              language={
                language === "js"
                  ? "javascript"
                  : language === "md" || language === "txt"
                  ? "text"
                  : language
              }
              value={
                createNewFile
                  ? ""
                  : language === "json"
                  ? formatJSON(content)
                  : content
              }
              options={{ minimap: { enabled: false } }}
            />
          )}
        </div>
        {previewUrl && !hidePreview && (
          <div
            className={`${
              isMobile
                ? `${
                    viewOption === "preview"
                      ? "w-full h-full"
                      : "w-full h-[50%]"
                  }`
                : `${
                    viewOption === "preview" ? "w-full h-full" : "w-2/4 h-full"
                  }`
            }`}
          >
            {/* Preview */}
            <Preview
              contract={contract}
              hidePreview={hidePreview}
              previewWidth={previewWidth}
              previewUrl={previewUrl}
              previewMarkdown={previewMarkdown}
              setPreviewWidth={setPreviewWidth}
              setContract={setContract}
              setHidePreview={setHidePreview}
              filePath={filePath}
            />
          </div>
        )}
      </div>
      {loading && <LoadingDialog />}
    </div>
  );
};

export default FileEditor;
