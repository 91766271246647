import { createSlice } from "@reduxjs/toolkit";

const requestLoginStatus = createSlice({
  name: "requestLogin",
  initialState: false,
  reducers: {
    requestLogin: (state, action) => {
      return true;
    },
    clearRequestLogin: (state) => {
      return false;
    },
  },
});

export const { requestLogin, clearRequestLogin } = requestLoginStatus.actions;
export default requestLoginStatus.reducer;
