import { createSlice } from "@reduxjs/toolkit";

const selectedNodeSlice = createSlice({
  name: "selectedNode",
  initialState: {},
  reducers: {
    setSelectedNode: (state, action) => {
      return action.payload;
    },
    clearSelectedNode: (state) => {
      return {};
    },
  },
});


export const { setSelectedNode, clearSelectedNode} = selectedNodeSlice.actions;
export default selectedNodeSlice.reducer;