import React, { useState } from "react";
import PropTypes from "prop-types";
// import { TextField } from "@mui/material";
import { Controller } from "react-hook-form";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { FormHelperText } from "@mui/material";
import { BiLock } from "react-icons/bi";

PasswordField.propTypes = {
  form: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  disabled: PropTypes.bool,
};

function PasswordField(props) {
  const [showPassword, setShowPassword] = useState(false);
  const { form, name, label, disabled } = props;
  const {
    formState: { errors, ...formState },
  } = form;
  const hasError = !!errors[name];

  const renderPlaceHoder = () => {
    switch (label) {
      case "Fullname":
        return "Name";
      case "Email":
        return "Email";
      case "Password":
        return "Password";
      case "Retype Password":
        return "Confirm Password";
      default:
        return label;
    }
  };

  return (
    <FormControl error={hasError} margin="normal" variant="outlined">
      <Controller
        name={name}
        control={form.control}
        render={({ field, fieldState, formState, name }) => (
          <OutlinedInput
            {...field}
            {...fieldState}
            {...formState}
            id={name}
            placeholder={renderPlaceHoder()}
            type={showPassword ? "text" : "password"}
            startAdornment={
              <InputAdornment position="start">
                <BiLock size="1.5rem"/>
              </InputAdornment>
            }
            disabled={disabled}
            className="bg-gray-100 hover:bg-white focus:bg-white"
          />
        )}
      />
      <FormHelperText error={!!hasError}>
        {errors[name]?.message}
      </FormHelperText>
    </FormControl>
  );
}

export default PasswordField;
