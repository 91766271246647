export const MEDIA_FILE = [
  "png",
  "jpg",
  "jpeg",
  "gif",
  "svg",
  "webp",
  "mp3",
  "mp4",
  "m4p",
  "m4v",
  "webm",
  "ogg",
  "mp2",
  "mpg",
  "mpv",
  "mov",
  "mpeg",
];

export const getPath = (node) => {
  let totalsplash = (node.path.match(/\//g) || []).length;
  if (totalsplash > 1) {
    node.path.substring(0, node.path.lastIndexOf("/"));
    return node.path.substring(0, node.path.lastIndexOf("/"));
  } else {
    return "/";
  }
};
