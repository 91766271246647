import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Editor, { DiffEditor, useMonaco, loader } from "@monaco-editor/react";

Input.propTypes = {};

function Input({ label, type, getInputValue, node }) {
  const [content, setContent] = useState("");
  const handleChange = (e) => {
    if (label === "Package Name") {
      getInputValue(e.target.value);
    } else if (label === "Entry file") {
      getInputValue(e.target.value);
    } else if (label === "Category") {
      getInputValue(e.target.value);
    } else if (label === "Visibility") {
      getInputValue(e.target.value);
    } else if (label === "Options") {
      getInputValue(e);
    } else if (label === "Version") {
      getInputValue(e.target.value);
    } else if (label === "Description") {
      getInputValue(e.target.value);
    }
  };

  const renderSelectCategory = () => {
    return (
      <select
        className="border rounded-md placeholder:italic placeholder:text-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 py-2 px-2 h-[46px]"
        onChange={(e) => {
          handleChange(e);
        }}
      >
        <option value="widget">Widget</option>
        <option value="app">Vehicle App</option>
      </select>
    );
  };

  const renderSelectVisibility = () => {
    return (
      <select
        className="border rounded-md placeholder:italic placeholder:text-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 py-2 px-2 h-[46px]"
        onChange={(e) => {
          handleChange(e);
        }}
      >
        <option value="public">Public</option>
        <option value="private">Private</option>
        <option value="archived">Archived</option>
        <option value="locked">Locked</option>
      </select>
    );
  };

  const renderDefaultValueByLabel = () => {
    if (label === "Package Name") {
      return node.name;
    } else if (label === "Entry file") {
      return "index.html";
    } else if (label === "Version") {
      return node;
    }
  };

  return (
    <div>
      {type === "text" && (
        <div className="mb-2">
          <label htmlFor={label} className="block text-sm mb-2 font-semibold">
            {label}
            <span className="ml-1 text-red-600">*</span>
          </label>
          <input
            type="text"
            required
            id="license"
            className="w-full !border border-[#8080803] py-3 px-4 block border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-blue-500 "
            placeholder={`Enter your ${label.toLowerCase()}`}
            autoFocus
            onChange={(e) => {
              handleChange(e);
            }}
            defaultValue={renderDefaultValueByLabel()}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
              }
            }}
          />
        </div>
      )}

      {type === "select" && (
        <div className="mb-2">
          <div className="block text-sm mb-2 font-semibold">
            {label}
            <span className="ml-1 text-red-600">*</span>
          </div>
          {label === "Category" && renderSelectCategory()}
          {label === "Visibility" && renderSelectVisibility()}
        </div>
      )}

      {type === "code" && (
        <div className="mb-2 h-[11rem]">
          <div className="block text-sm mb-2 font-semibold">
            {label}
            <span className="ml-1 text-red-600">*</span>
          </div>
          <Editor
            className="w-full"
            height="80%"
            defaultLanguage="json"
            defaultValue="{}"
            onChange={handleChange}
            options={{ minimap: { enabled: false } }}
            lineNumbers="off"
            value={content}
          />
        </div>
      )}

      {type === "textarea" && (
        <div className="mb-2">
          <label htmlFor={label} className="block text-sm mb-2 font-semibold">
            {label}
            <span className="ml-1 text-red-600">*</span>
          </label>
          <textarea
            className="w-full py-3 px-4 h-24 max-h-24 !border border-[#8080803] block border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-blue-500"
            onChange={(e) => {
              handleChange(e);
            }}
          ></textarea>
        </div>
      )}
    </div>
  );
}

export default Input;
