import { createSlice } from "@reduxjs/toolkit";

const activeNodeSlice = createSlice({
  name: "activeNode",
  initialState: { name: "", path: "/", isDir: true },
  reducers: {
    setActiveNode: (state, action) => {
      return action.payload;
    },
    clearActiveNode: (state) => {
      return { name: "", path: "/", isDir: true };
    },
  },
});

export const { setActiveNode, clearActiveNode } = activeNodeSlice.actions;
export default activeNodeSlice.reducer;
