import { createSlice } from "@reduxjs/toolkit";

const RenameProjectSlice = createSlice({
  name: "RenameProjectState",
  initialState: false,
  reducers: {
    openModal: (state) => {
      state.isOpen = true;
    },
    closeModal: (state) => {
      state.isOpen = false;
    },
  },
});
export const { openModal, closeModal } = RenameProjectSlice.actions;
export default RenameProjectSlice.reducer;
