import React from "react";
import PropTypes from "prop-types";
// import { TextField } from "@mui/material";
import TextField from "@mui/material/TextField";
import { Controller } from "react-hook-form";
import { InputAdornment } from "@mui/material";
import { MdOutlineAlternateEmail } from "react-icons/md";
import {CgHello} from "react-icons/cg"
import {RxAvatar} from "react-icons/rx"

InputField.propTypes = {
  form: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  disabled: PropTypes.bool,
};

function InputField(props) {
  const { form, name, label, disabled } = props;
  const {
    formState: { errors, ...formState },
  } = form;

  const hasError = errors[name];
  // console.log(errors[name]);

  const renderPlaceHoder = () => {
    switch (label) {
      case "Fullname":
        return "Name";
      case "Email":
        return "Email";
      case "Password":
        return "Password";
      case "Retype Password":
        return "Confirm Password";
      default:
        return label;
    }
  };

  const renderIcon = () => {
    switch (label) {
      case "Fullname":
        return <RxAvatar size="1.5rem"/>;
      case "Email":
        return <MdOutlineAlternateEmail size="1.5rem"/>;
      default:
        return <CgHello size="1.5rem"/>;
    }
  };


  return (
    <Controller
      name={name}
      control={form.control}
      disabled={disabled}
      render={({ field, fieldState, formState }) => (
        <TextField
          {...field}
          {...fieldState}
          {...formState}
          variant="outlined"
          placeholder={renderPlaceHoder()}
          margin="normal"
          error={!!hasError}
          helperText={errors[name]?.message}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                {renderIcon()}
              </InputAdornment>
            ),
          }}
          className="bg-gray-100 hover:bg-white"
        />
      )}
    />
  );
}

export default InputField;
