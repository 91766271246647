//TODO:
// Render filter array
import * as React from "react";
import { styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import { MdOutlineClear } from "react-icons/md";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: "20px",
  backgroundColor: "rgb(248 250 252)",
  border: "1px solid #00507266",
  "&:hover": {
    backgroundColor: "white",
    border: "1px solid #005072",
  },
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  opacity: 0.6,
  color: "#00507266",
  "&:hover": {
    color: "#005072",
    opacity: 1,
  },
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    width: "1ch",
    "&:focus": {
      width: "10ch",
    },
    [theme.breakpoints.up("sm")]: {
      width: "13ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

export default function SearchAppBar({ onSearchResult, allProjects }) {
  const [searchKey, setSearchKey] = React.useState("");

  const handleClearInput = () => {
    setSearchKey(""); // Clear the search term
    onSearchResult(allProjects, false);
  };

  const handleSearch = (e) => {
    const searchTerm = e.target.value;
    setSearchKey(searchTerm);

    const filtered = allProjects.filter((item) =>
      item.originalname.toLowerCase().includes(searchTerm.toLowerCase())
    );
    onSearchResult(filtered, true);
  };

  return (
    <Search sx={{ display: "flex", alignItems: "center" }}>
      <SearchIconWrapper>
        <SearchIcon />
      </SearchIconWrapper>
      <StyledInputBase
        placeholder="Search…"
        inputProps={{ "aria-label": "search" }}
        onChange={(e) => {
          handleSearch(e);
        }}
        value={searchKey}
        sx={{
          height: "100%",
        }}
      />
      {searchKey != "" && (
        <MdOutlineClear
          // className="cursor-pointer absolute top-3 right-2.5"
          className="cursor-pointer mr-2.5"
          onClick={handleClearInput}
        />
      )}
    </Search>
  );
}
