import axios from "axios";
import { STATIC_UPLOAD_URL } from "../config";

const axiosConfig = {
  headers: {
    "Cache-Control": "no-cache",
  },
};

export const uploadImage = async (file, setUploaded_file) => {
  try {
    const formData = new FormData();
    formData.append("file", file);
    const res = await axios.post(`${STATIC_UPLOAD_URL}`, formData, axiosConfig);
    setUploaded_file(res.data);
  } catch (error) {
    console.log("Failed to upload thumnails", error);
  }
};
