import { createSlice } from "@reduxjs/toolkit";

const selectedTypeSlice = createSlice({
  name: "selectedType",
  initialState: "",
  reducers: {
    setSelectedType: (state, action) => {
      return action.payload;
    },
    clearSelectedType: (state) => {
      return "";
    },
  },
});


export const { setSelectedType, clearSelectedType} = selectedTypeSlice.actions;
export default selectedTypeSlice.reducer;