// store.js
import { configureStore } from "@reduxjs/toolkit";
import selectedNodeReducer from "./selectedNodeSlice";
import selectedPathReducer from "./selectedPathSlice";
import selectedTypeReducer from "./selectedTypeSlice";
import dropProjectReducer from "./dropProjectSlice";
import selectedURLReducer from "./selectedURLSlice";
import renameProjectReducer from "./RenameProjectSlice";
import activeNodeReducer from "./activeNodeSlice";
import setNodeReducer from "./nodeSlice";
import selectedProjectReducer from "./selectedProjectSlice";
import selectedIndexReducer from "./selectedIndexSlice";
import modeStateReducer from "./modeStateSlice";
import requestLoginStatusReducer from "./requestLoginStatus";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
import { combineReducers } from "@reduxjs/toolkit";

// const persistConfig = {
//   key: "root",
//   storage,
// };

// const reducer = combineReducers({
//   modeState: modeStateReducer,

// });

// const persistedReducer = persistReducer(persistConfig, reducer);

const store = configureStore({
  reducer: {
    selectedNode: selectedNodeReducer,
    selectedPath: selectedPathReducer,
    selectedType: selectedTypeReducer,
    dropProject: dropProjectReducer,
    selectedURL: selectedURLReducer,
    setNode: setNodeReducer,
    modalState: renameProjectReducer,
    selectedProject: selectedProjectReducer,
    activeNode: activeNodeReducer,
    selectedIndex: selectedIndexReducer,
    modeState: modeStateReducer,
    requestLoginStatus: requestLoginStatusReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export default store;
