import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link, useNavigate, useParams } from "react-router-dom";
import { AiOutlineMore } from "react-icons/ai";
import { Menu, MenuItem } from "@mui/material";
import { MdOutlineDriveFileRenameOutline } from "react-icons/md";
import { MdDelete } from "react-icons/md";
import { PiDownloadBold } from "react-icons/pi";
import { MdOutlineWidgets } from "react-icons/md";

import JSZip from "jszip";
import { saveAs } from "file-saver";
import JSZipUtils from "jszip-utils";
import axios from "axios";
import { useSelector } from "react-redux";
import { STATIC_HOST } from "../../api/config";
import { getChildren } from "../../api/folderAPI";

GridRender.propTypes = {};

function GridRender({
  currentItems,
  OpenMenu,
  anchorEl,
  handleClose,
  handleRenameProject,
  handleDeleteProject,
  open,
}) {
  const navigate = useNavigate();
  const [projectChildren, setProjectChildren] = useState([]);
  const selectedProject = useSelector((state) => state.selectedProject);

  const fetchData = async () => {
    try {
      const res = await axios.get(
        `${STATIC_HOST}project/${selectedProject?.name}`,
        {
          headers: {
            "Cache-Control": "no-cache",
          },
        }
      );
      setProjectChildren(res.data.children);
    } catch (err) {
      console.log("Error fetching project", err);
    }
  };

  useEffect(() => {
    if (selectedProject && selectedProject.name) fetchData();
  }, [selectedProject]);


  const getContentFile = async (fileURL) => {
    const res = await axios.get(
      `${STATIC_HOST}project/${selectedProject.name}/file`,
      {
        params: { path: fileURL }, //get content file
      }
    );
    return res.data.content;
  };

  const handleDownloadZip = async (e) => {
    function urlToPromise(url) {
      return new Promise(function (resolve, reject) {
        JSZipUtils.getBinaryContent(url, function (err, data) {
          if (err) {
            reject(err);
          } else {
            resolve(data);
          }
        });
      });
    }

    var zip = new JSZip();

    const MEDIA_FILE = ["png", "jpg", "jpeg", "gif", "svg", "webp", "mp3", "mp4", "m4p", "m4v", "webm", "ogg", "mp2", "mpg", "mpv", "mov", "mpeg"];

    const getItems = async (parent, currentFolder) => {
      if (parent.isDir === true) {
        let folder = currentFolder.folder(parent.name); // Create a sub-folder inside the current folder
        const children = await getChildren(parent.path);
        children?.forEach((child) => {
          getItems(child, folder); // Pass the current sub-folder to maintain the structure
        });
      } else {
        {
          try {
            if (
              MEDIA_FILE.includes(
                parent.name
                  .substring(parent.name.lastIndexOf(".") + 1)
                  .toLowerCase()
              )
            ) {
              const content = parent.fileLink;
              currentFolder.file(parent.name, urlToPromise(content), {
                binary: true,
              });
            } else {
              const content = await getContentFile(`${parent.path}`);
              currentFolder.file(parent.name, content);
            }
          } catch (error) {
            console.error(`Error fetching file ${parent.name}:`, error);
          }
        }
      }
    };

    for (let i = 0; i < projectChildren.length; i++) {
      await getItems(projectChildren[i], zip); // Start with the main zip object
    }

    zip.generateAsync({ type: "blob" }).then(function (content) {
      saveAs(content, `${selectedProject.originalname}.zip`);
    });
  };

  return (
    <div className="grid lg:grid-cols-6 sm:grid-cols-3 xs:grid-cols-2 gap-4">
      {currentItems?.map((file, index) => {
        return (
          <div className="relative" key={index}>
            <div
              className="inline-flex items-center rounded-2xl text-base w-full px-5 py-5 
                          bg-slate-100 border-b hover:bg-slate-200 cursor-pointer flex-col justify-center"
              onClick={() =>
                navigate("/project/" + file.name, {
                  state: {
                    file: file,
                  },
                })
              }
            >
              <MdOutlineWidgets size="4rem" color="#BDBDBE" />

              <Link
                to={"/project/" + file.name}
                state={{ file: file }}
                className="grow w-full font-medium truncate text-center"
              >
                {file.originalname}
              </Link>
              <AiOutlineMore
                className="absolute top-2 right-2 cursor-pointer"
                onClick={OpenMenu}
                file={JSON.stringify(file)}
              />
            </div>
          </div>
        );
      })}
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          onClick={(e) => handleRenameProject(e)}
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            gap: "10px",
            paddingLeft: "13px",
            paddingRight: "13px",
          }}
        >
          <MdOutlineDriveFileRenameOutline />
          Rename
        </MenuItem>
        <MenuItem
          onClick={handleDeleteProject}
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            gap: "10px",
            paddingLeft: "13px",
            paddingRight: "13px",
          }}
        >
          <MdDelete />
          Delete
        </MenuItem>
        <MenuItem
          onClick={handleDownloadZip}
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            gap: "10px",
            paddingLeft: "13px",
            paddingRight: "13px",
          }}
        >
          <PiDownloadBold />
          Download
        </MenuItem>
      </Menu>
    </div>
  );
}

export default GridRender;
