import { createSlice } from "@reduxjs/toolkit";

const modeStateSlice = createSlice({
  name: "modeState",
  initialState: "",
  reducers: {
    setModeState: (state, action) => {
      return action.payload;
    },
    clearModeState: (state) => {
      return "";
    },
  },
});

export const { setModeState, clearModeState } = modeStateSlice.actions;
export default modeStateSlice.reducer;
