import React, { useState } from "react";
import PropTypes from "prop-types";
import Tooltip from "@mui/material/Tooltip";
import { IoReorderFourOutline } from "react-icons/io5";
import { MdVerticalSplit } from "react-icons/md";
import { TbRectangleVerticalFilled } from "react-icons/tb";
import { LuFolderTree } from "react-icons/lu";
import { getLogo } from "../../../utils/func/getLogo";

EditorHeader.propTypes = {};

function EditorHeader({
  openExplorer,
  setOpenExplorer,
  width,
  filePath,
  fileType,
  viewOption,
  handleOpenEditor,
  handleSplit,
  handleOpenPreview,
  isSaving,
  contentChanged,
  handleFileEdit,
  onOpenExplorer
}) {
  return (
    <div>
      {/* Head line */}
      <div className="flex bg-slate-200 px-4 py-1 items-center">
        <div className="grow font-mono font-semibold truncate flex items-center gap-[1vw]">
          <Tooltip title="Open/Close File Explorer" arrow placement="bottom">
            <button
              className={`px-[3px] py-[3px]  ${
                openExplorer ? "border rounded border-blue-500" : "border-black"
              }`}
              onClick={() => {
                if (openExplorer === true) {
                  setOpenExplorer(false);
                } else {
                  setOpenExplorer(true);
                }
                onOpenExplorer(openExplorer);
              }}
            >
              <LuFolderTree
                size={`${width < 620 ? "0.8rem" : "1rem"}`}
                color={`${openExplorer ? "#3b82f6" : "black"}`}
              />
            </button>
          </Tooltip>
          <div
            className={`flex items-center gap-1 ${
              width <= 620 ? "text-[11px]" : "text-base"
            }`}
          >
            <span className="">{getLogo(filePath)}</span>
            {filePath?.substring(filePath?.lastIndexOf("/") + 1) || "no name"}
          </div>
        </div>

        {(fileType === "html" || fileType === "md") && (
          <div
            className={`flex justify-between text-[11px] mr-[1vw] ${
              width <= 620 ? "w-40" : "w-48"
            }`}
          >
            <button
              className={`flex justify-center items-center px-1 py-[3px] ${
                viewOption === "code"
                  ? "font-bold rounded border border-slate-700"
                  : ""
              }`}
              onClick={handleOpenEditor}
            >
              <IoReorderFourOutline
                size={`${width < 620 ? "0.8rem" : "1.4rem"}`}
              />
              Code
            </button>
            <button
              className={`flex justify-center items-center px-1 ${
                viewOption === "split"
                  ? "font-bold rounded border border-slate-700"
                  : ""
              }`}
              onClick={handleSplit}
            >
              <MdVerticalSplit size={`${width < 620 ? "0.8rem" : "1.56rem"}`} />
              Split
            </button>
            <button
              className={`flex justify-center items-center px-1 ${
                viewOption === "preview"
                  ? "font-bold rounded border border-slate-700"
                  : ""
              }`}
              onClick={handleOpenPreview}
            >
              <TbRectangleVerticalFilled
                size={`${width < 620 ? "0.8rem" : "1.3rem"}`}
              />
              Preview
            </button>
          </div>
        )}

        <button
          className={`${
            isSaving || !contentChanged
              ? "bg-slate-400"
              : "bg-blue-500 hover:opacity-80"
          } py-[0.34rem] px-4 text-sm inline-flex justify-center items-center gap-2 rounded-md 
                  border border-transparent font-semibold text-white  focus:outline-none 
                  focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-all dark:focus:ring-offset-gray-800`}
          onClick={handleFileEdit}
          disabled={isSaving || !contentChanged}
        >
          {isSaving ? "Saving..." : "Save"}
        </button>
      </div>
      {/* path */}
      <div className="text-[11px] text-slate-500 bg-[#efefef] pl-2 py-[3px]">
        {encodeURIComponent(filePath?.slice(1)).replaceAll("%2F", " > ")}
      </div>
    </div>
  );
}

export default EditorHeader;
