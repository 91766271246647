import * as React from "react";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import { useDispatch } from "react-redux";
import { setSelectedIndex } from "../../store/selectedIndexSlice";

const options = [
  "New Empty Project",
  "New Sample Widget",
  "New Sample Simulator",
  "New Sample Website",
];

export default function CreateProjectBtn({ onOpenCreateProjectModal }) {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [onChooseIndex, setOnChooseIndex] = React.useState(0);

  const handleClick = () => {
    onOpenCreateProjectModal();
  };

  const dispatch = useDispatch();
  React.useEffect(() => {
    if (onChooseIndex) {
      dispatch(setSelectedIndex(onChooseIndex));
    }
  }, [onChooseIndex]);

  const handleMenuItemClick = (event, index) => {
    onOpenCreateProjectModal();
    setOnChooseIndex(index);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  return (
    <React.Fragment>
      <ButtonGroup
        variant="contained"
        ref={anchorRef}
        aria-label="split button"
        className="!bg-[#005072]"
        sx={{
          borderRadius: "10px",
        }}
      >
        <Button
          onClick={handleClick}
          className="!bg-[#005072] border-r border-white"
          sx={{
            borderRight: "1px solid white !important",
            padding: "11px 10px",
            borderRadius: "1.5rem",
          }}
        >
          {options[onChooseIndex]}
        </Button>
        <Button
          size="small"
          aria-controls={open ? "split-button-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          onClick={handleToggle}
          className="rounded-3xl !bg-[#005072]"
          sx={{
            borderRadius: "1.5rem",
          }}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper
        sx={{
          zIndex: 1,
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {options?.map((option, index) => (
                    <MenuItem
                      key={option}
                      //   disabled={index === 2}
                      selected={index === onChooseIndex}
                      onClick={(event) => handleMenuItemClick(event, index)}
                    >
                      {option}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </React.Fragment>
  );
}
