import { useSearchParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

function useUserId () {
    const [searchParams] = useSearchParams();
    let queryUid = searchParams.get("uid");
    if(queryUid) {
        localStorage.setItem("uid", queryUid);
        return queryUid
    }
    let storedUserId = localStorage.getItem("uid");
    if (!storedUserId) {
        let storedUserId = uuidv4();
        localStorage.setItem("uid", storedUserId);
      }
    return storedUserId
}

export default useUserId