import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import useProjectCode from "../../utils/CustomHook/useProjectCode";
import Input from "../Input/Input";
import { toast } from "react-toastify";
import { getPackageVersion, updatePackageVersion } from "../../api/projectAPI";

const style = {
  position: "absolute",
  top: "40%",
  left: "50%",
  transform: "translate(-50%, -40%)",
  transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;",
  width: "34%",
  height: "54%",
  bgcolor: "background.paper",
  boxShadow:
    "rgba(0, 0, 0, 0.2) 0px 11px 15px -7px, rgba(0, 0, 0, 0.14) 0px 24px 38px 3px, rgba(0, 0, 0, 0.12) 0px 9px 46px 8px",
  borderRadius: "10px",
};

export default function VersionUpdate({
  isOpenVersionModal,
  handleClose,
  deployPackageId,
}) {
  const [onEnter, setOnEnter] = React.useState();
  const [version, setVersion] = React.useState("");
  const [versionDesc, setVersionDesc] = React.useState("");

  const projectCode = useProjectCode();
  const handleUpdateVers = async () => {
    let res = await updatePackageVersion(projectCode, version, versionDesc);
    if (res) {
      toast.success("Updated version successfully");
      handleClose();
    } else {
      toast.error("Failed to update version");
      handleClose();
    }
  };

  React.useEffect(() => {
    const getVersion = async () => {
      let res = await getPackageVersion(deployPackageId);
      if (res) {
        setVersion(res.data[0].name);
      }
    };
    getVersion();
  }, [deployPackageId]);

  return (
    <div>
      <Modal
        open={isOpenVersionModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          transitionProperty: "all",
          transitionTimingFunction: "cubic-bezier(0, 0, 0.2, 1)",
          transitionDuration: "150ms",
          marginTop: "-4px",
        }}
      >
        <Box sx={style}>
          {/* Dialog title */}
          <div className="font-bold text-gray-800 flex justify-between items-center !border-b px-6 py-3">
            Update Version
            <button
              onClick={() => {
                handleClose();
              }}
              type="button"
              className="hs-dropdown-toggle inline-flex flex-shrink-0 justify-center items-center h-8 w-8 rounded-md text-gray-500 hover:text-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-offset-2 focus:ring-offset-white transition-all text-sm dark:focus:ring-gray-700 dark:focus:ring-offset-gray-800"
            >
              <span className="sr-only">Close</span>
              <svg
                className="w-3.5 h-3.5"
                width="8"
                height="8"
                viewBox="0 0 8 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.258206 1.00652C0.351976 0.912791 0.479126 0.860131 0.611706 0.860131C0.744296 0.860131 0.871447 0.912791 0.965207 1.00652L3.61171 3.65302L6.25822 1.00652C6.30432 0.958771 6.35952 0.920671 6.42052 0.894471C6.48152 0.868271 6.54712 0.854471 6.61352 0.853901C6.67992 0.853321 6.74572 0.865971 6.80722 0.891111C6.86862 0.916251 6.92442 0.953381 6.97142 1.00032C7.01832 1.04727 7.05552 1.1031 7.08062 1.16454C7.10572 1.22599 7.11842 1.29183 7.11782 1.35822C7.11722 1.42461 7.10342 1.49022 7.07722 1.55122C7.05102 1.61222 7.01292 1.6674 6.96522 1.71352L4.31871 4.36002L6.96522 7.00648C7.05632 7.10078 7.10672 7.22708 7.10552 7.35818C7.10442 7.48928 7.05182 7.61468 6.95912 7.70738C6.86642 7.80018 6.74102 7.85268 6.60992 7.85388C6.47882 7.85498 6.35252 7.80458 6.25822 7.71348L3.61171 5.06702L0.965207 7.71348C0.870907 7.80458 0.744606 7.85498 0.613506 7.85388C0.482406 7.85268 0.357007 7.80018 0.264297 7.70738C0.171597 7.61468 0.119017 7.48928 0.117877 7.35818C0.116737 7.22708 0.167126 7.10078 0.258206 7.00648L2.90471 4.36002L0.258206 1.71352C0.164476 1.61976 0.111816 1.4926 0.111816 1.36002C0.111816 1.22744 0.164476 1.10028 0.258206 1.00652Z"
                  fill="currentColor"
                />
              </svg>
            </button>
          </div>
          {/* Dialog Content */}
          <div className="!px-6 py-4 !border-b">
            <div>
              <Input
                label="Version"
                type="text"
                getInputValue={(input) => setVersion(input)}
                node={version}
              />
              <Input
                label="Description"
                type="textarea"
                getInputValue={(input) => setVersionDesc(input)}
                node={null}
              />
            </div>
          </div>
          {/* Dialog action */}
          <div className="pr-5 pt-4 flex justify-end gap-2">
            <Button
              onClick={() => {
                handleClose();
              }}
              sx={{
                bgcolor: "white",
                color: "rgb(55 65 81)",
                padding: "12px 16px",
                border: "1px solid #80808033",
                "&:hover": {
                  backgroundColor: "rgb(249 250 251)",
                },
                borderRadius: "0.375rem",
              }}
            >
              Close
            </Button>

            <Button
              onClick={handleUpdateVers}
              disabled={version === ""}
              sx={{
                padding: "12px 16px",
                backgroundColor:
                  onEnter === true ? "rgb(37 99 235)" : "rgb(59 130 246)",
                color: "white",
                "&:hover": {
                  backgroundColor: "rgb(37 99 235)",
                },
                "&:disabled": {
                  backgroundColor: "rgb(147 197 253) !important",
                  color: "white !important",
                },
                borderRadius: "0.375rem",
              }}
            >
              Update Version
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
