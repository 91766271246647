export const noJSContent = `
<!doctype html>
<html>

<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <script src="https://cdn.tailwindcss.com"></script>
    <script defer src="https://bestudio.digitalauto.tech/project/BzR91b49OHqj/syncer.js"></script>
</head>

<body class="h-screen grid place-items-center bg-slate-100 select-none">
    <div class="w-[280px] p-6 bg-slate-300 rounded-lg text-left text-slate-700">
        <div class="text-center font-semi-bold text-lg">Light Low Beam</div>
        
        <div class='mt-4 flex'> 
            <div vss='true' 
                vss-name='Vehicle.Body.Lights.IsLowBeamOn' 
                class="bg-slate-100 text-center font-bold text-gray-600 w-full rounded px-4 py-2 hover:opacity-80">
                </div>
        </div>

        
        <div class='mt-4 flex'>
            <div vss='true' 
                vss-name='Vehicle.Body.Lights.IsLowBeamOn' 
                action='set::true'
                class="bg-teal-500 rounded px-4 py-2 text-white cursor-pointer hover:opacity-80">
                Turn ON</div>
                
            <div class="grow"></div>

            <div vss='true' 
                vss-name='Vehicle.Body.Lights.IsLowBeamOn' 
                action='set::false'
                class="bg-red-500 rounded px-4 py-2 text-white cursor-pointer hover:opacity-80">
                Turn OFF</div>
        </div>
    </div>
</body>

</html>`;

export const withJSContent = `
<!doctype html>
<html>

<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <script src="https://cdn.tailwindcss.com"></script>
    <script>
        let API_NAME = "Vehicle.Body.Lights.IsLowBeamOn"
        let interval = null
        let textValue = document.getElementById("label_value")
        function onWidgetLoaded(options) {
            console.log("On my widget loaded")
            interval = setInterval(() => {
                if(textValue) {
                    let apiValue = getApiValue(API_NAME)
                    console.log("type of apiValue", typeof apiValue)
                    textValue.innerText = apiValue
                }
            }, 500)
        }
        function onWidgetUnloaded(options) {
            console.log("On my widget unloaded")
            if(interval) clearInterval(interval)
        }

        let btnSetOn = document.getElementById("btnSetOn")
        let btnSetOff = document.getElementById("btnSetOff")

        btnSetOn.addEventListener("click", () => {
            setApiValue(API_NAME, true)
        })

        btnSetOff.addEventListener("click", () => {
            setApiValue(API_NAME, false)
        })
    </script>
    <script defer src="https://bestudio.digitalauto.tech/project/BzR91b49OHqj/syncer.js"></script>
    
</head>

<body class="h-screen grid place-items-center bg-slate-100 select-none">
    <div class="w-[280px] p-6 bg-slate-300 rounded-lg text-left text-slate-700">
        <div class="text-center font-semi-bold text-lg">Light Low Beam</div>
        <div class='mt-4 flex'>
            <div id='label_value' 
                class="bg-slate-100 text-center font-bold text-gray-600 w-full rounded px-4 py-2 hover:opacity-80">
                unknown
                </div>
        </div>

        
        <div class='mt-4 flex'>
            <div id="btnSetOn"
                class="bg-teal-500 rounded px-4 py-2 text-white cursor-pointer hover:opacity-80">
                Turn ON</div>
                
            <div class="grow"></div>

            <div id="btnSetOff"
                class="bg-red-500 rounded px-4 py-2 text-white cursor-pointer hover:opacity-80">
                Turn OFF</div>
        </div>
    </div>
</body>

</html>
`;
