import axios from "axios";
import { getFileTypeFromName } from "../../utils/func/filename";
import useCheckUser from "../../utils/CustomHook/useCheckUser";
import { STATIC_HOST } from "../config";

const isLoggedIn = useCheckUser();
const axiosConfig = {
  headers: {
    "Cache-Control": "no-cache",
    "Content-Type": "application/json",
  },
};

if (isLoggedIn) {
  axiosConfig.headers.Authorization = "Bearer " + localStorage.getItem("token");
}

//GET METHOD
export const getProjectChildren = async (id, setProjectChildren) => {
  try {
    const res = await axios.get(`${STATIC_HOST}project/${id}`, {
      headers: {
        "Cache-Control": "no-cache",
      },
    });
    let projectChildren = [];
    if (res.data.children && res.data.children.length) {
      projectChildren = await Promise.all(
        res.data.children.map(async (file) => {
          if (file.isDir) {
            return {
              name: file.name,
              path: file.path,
              isDir: file.isDir,
            };
          } else {
            return {
              fileLink: file.fileLink,
              thumbnail: file.thumbnail,
              name: file.name,
              path: file.path,
              isDir: file.isDir,
              filename: file.name,
              type: getFileTypeFromName(file.name),
            };
          }
        })
      );
    }
    setProjectChildren(projectChildren);
  } catch (err) {
    console.log("error fetching file data", err);
  }
};

export const getRootChildren = async (id) => {
  try {
    const res = await axios.get(`${STATIC_HOST}project/${id}`, {
      headers: {
        "Cache-Control": "no-cache",
      },
    });
    let projectChildren = [];
    if (res.data.children && res.data.children.length) {
      projectChildren = await Promise.all(
        res.data.children.map(async (file) => {
          if (file.isDir) {
            return {
              name: file.name,
              path: file.path,
              isDir: file.isDir,
            };
          } else {
            return {
              fileLink: file.fileLink,
              thumbnail: file.thumbnail,
              name: file.name,
              path: file.path,
              isDir: file.isDir,
              filename: file.name,
              type: getFileTypeFromName(file.name),
            };
          }
        })
      );
    }
    return projectChildren;
  } catch (err) {
    console.log("error fetching file data", err);
  }
};

export const getAllFiles = async (projectChildren, projectCode) => {
  // Implement the logic for getAllFiles here
  const getItems = async (parent, accumulatedFiles) => {
    if (parent.isDir === true) {
      const children = await getChildren(parent.path, projectCode);
      children.forEach((child) => {
        getItems(child, accumulatedFiles);
      });
    } else {
      accumulatedFiles.push(parent);
    }
  };

  const accumulatedFiles = [];
  for (let i = 0; i < projectChildren.length; i++) {
    await getItems(projectChildren[i], accumulatedFiles);
  }

  let fileStore = JSON.stringify(accumulatedFiles);
  localStorage.setItem(`${projectCode}_files`, fileStore);
};

export const getChildren = async (foldepath, projectCode) => {
  if (!projectCode) return [];
  try {
    let res = await axios.get(`${STATIC_HOST}project/${projectCode}/dir`, {
      params: { path: foldepath },
    });
    return res.data.children;
  } catch (err) {
    console.log("getChildren error", err);
    return [];
  }
};

export const getContentFile = async (projectCode, fileURL) => {
  try {
    const res = await axios.get(`${STATIC_HOST}project/${projectCode}/file`, {
      params: { path: fileURL }, //get content file
    });
    return res.data.content;
  } catch (err) {
    console.log("error", err);
  }
};

//PUT METHOD
export const renameItem = async (projectCode, node, finalFilename) => {
  try {
    await axios.put(
      `${STATIC_HOST}project/${projectCode}/rename`,
      {
        newPath:
          node.path.substr(0, node.path.lastIndexOf("/")) + "/" + finalFilename,
      },
      {
        headers: {
          "Cache-Control": "no-cache",
          "Content-Type": "application/json",
          Authorization: isLoggedIn
            ? "Bearer " + localStorage.getItem("token")
            : "",
        },
        params: {
          path: node.path,
        },
      }
    );
  } catch (error) {
    console.log("error", error);
  }
};

//DELETE METHOD
export const deleteProject = async (projectCode) => {
  try {
    let res = await axios.delete(
      `${STATIC_HOST}project/${projectCode}`,
      axiosConfig
    );
    if (res) {
      return res;
    }
  } catch (error) {
    console.error(error);
  }
};

export const deleteDir = async (projectCode, node) => {
  try {
    const res = await axios.delete(`${STATIC_HOST}project/${projectCode}/dir`, {
      headers: {
        "Cache-Control": "no-cache",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      params: {
        path: node?.path,
      },
    });
    if (res) {
      return res;
    }
  } catch (error) {
    console.error(error);
    handleClose();
  }
};

export const deleteFile = async (projectCode, node) => {
  try {
    const res = await axios.delete(
      `${STATIC_HOST}project/${projectCode}/file`,
      {
        headers: {
          "Cache-Control": "no-cache",
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        params: {
          path: node?.path,
        },
      }
    );
    if (res) {
      return res;
    }
  } catch (error) {
    console.error(error);
  }
};

