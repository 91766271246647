import { createSlice } from "@reduxjs/toolkit";

const selectedIndexSlice = createSlice({
  name: "selectedIndex",
  initialState: 0,
  reducers: {
    setSelectedIndex: (state, action) => {
      return action.payload;
    },
    clearSelectedIndex: (state) => {
      return 0;
    },
  },
});

export const { setSelectedIndex, clearSelectedIndex } =
  selectedIndexSlice.actions;
export default selectedIndexSlice.reducer;
