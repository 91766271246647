import { createSlice } from "@reduxjs/toolkit";

const selectedProjectSlice = createSlice({
  name: "selectedProject",
  initialState: {},
  reducers: {
    setSelectedProject: (state, action) => {
        return action.payload;
    },
  },
});

export const { setSelectedProject } = selectedProjectSlice.actions;
export default selectedProjectSlice.reducer;
