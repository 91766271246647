import { STATIC_HOST } from './config'
import { htmlTemplate } from '../utils/Template/htmlTemplate'
import { axiosInstance } from './axios'

const axiosConfig = {
    headers: {
        'Cache-Control': 'no-cache',
    },
    params: {
        excludeRoot: false,
    },
}

if (localStorage.getItem('token')) {
    axiosConfig.headers.Authorization = 'Bearer ' + localStorage.getItem('token')
}

export const getFileContent = async (projectCode, filePath) => {
    try {
        const res = await axiosInstance.get(`${STATIC_HOST}project/${projectCode}/file`, {
            params: { path: filePath },
        })
        return res.data
    } catch (e) {
        console.log('Error on loading file: ', e)
    }
}

export const editFile = async (projectCode, content, filePath) => {
    try {
        let res = await axiosInstance.put(
            `${STATIC_HOST}project/${projectCode}/file`,
            {
                content: content || '',
            },
            { ...axiosConfig, params: { ...axiosConfig.params, path: filePath } }
        )
        return res.data
    } catch (error) {
        console.error('Error editing file:', error)
    }
}

export const uploadFile = async (projectID, formData, params) => {
    try {
        const response = await axiosInstance.post(`${STATIC_HOST}project/${projectID}/upload-file`, formData, {
            ...axiosConfig,
            params,
        })
        return response.data
    } catch (error) {
        console.log('error creating file', error)
    }
}

export const createNewFile = async (projectCode, finalFilename, path, language) => {
    try {
        let res = await axiosInstance.post(
            `${STATIC_HOST}project/${projectCode}/file`,
            {
                filename: finalFilename,
                content: language === 'html' ? htmlTemplate : '',
                path: path,
            },
            axiosConfig
        )
        return res.data
    } catch (error) {
        console.log('Error creating new file ' + error)
    }
}

export const renameFile = async (projectCode, finalFilename, node) => {
    try {
        let res = await axiosInstance.put(
            `${STATIC_HOST}project/${projectCode}/rename`,
            {
                newPath: node.path.substr(0, node.path.lastIndexOf('/')) + '/' + finalFilename,
            },
            { ...axiosConfig, params: { ...axiosConfig.params, path: node.path } }
        )
        return res
    } catch (error) {
        console.error('Error occurred while updating the file:', error)
        handleClose()
    }
}

export const uploadExistFiles = async (projectCode, formData) => {
    try {
        let response = await axiosInstance.post(`${STATIC_HOST}project/${projectCode}/upload-file-bulk`, formData, {
            ...axiosConfig,
            params: { ...axiosConfig.params, force: true },
        })
        return response
    } catch (error) {
        console.log('Error occurred while overwrite the file:', error)
    }
}
