import { VscFolder } from "react-icons/vsc";

import { AiFillHtml5 } from "react-icons/ai";
import { DiJavascript1 } from "react-icons/di";
import Css_Logo from "../images/css.png";
import Json_Logo from "../images/json.png";
import Info from "../images/infor_logo.png";
import Python from "../images/Python-logo.png";
import JS_Logo from "../images/javascript.png";
import MP3 from "../images/mp3.png";
import { getFileTypeFromName } from "./filename";

import { FiImage } from "react-icons/fi";
import { GoVideo } from "react-icons/go";

const IMAGE_FILE = ["png", "jpg", "jpeg", "gif", "svg", "webp"];
const VIDEO_FILE = [
  "mp3",
  "mp4",
  "m4p",
  "m4v",
  "webm",
  "ogg",
  "mp2",
  "mpg",
  "mpv",
  "mov",
  "mpeg",
];

export const getLogo = (file) => {
  if (!file) return null;
  if (file.isDir) return <VscFolder />;
  let type = file.name
    ? getFileTypeFromName(file.name).toLowerCase()
    : getFileTypeFromName(file).toLowerCase();
  let logo = null;
  if (type === "js" || type === "javascript") {
    // logo = <DiJavascript1 size="17px" color="#F0DB4F"/>;
    logo = <img src={JS_Logo} alt="javascript Logo" className="w-[15px]" />;
  } else if (type === "mp3") {
    logo = <img src={MP3} alt="mp3 Logo" className="w-[15px]" />;
  } else if (type === "css") {
    logo = <img src={Css_Logo} alt="Css Logo" className="w-[15px]" />;
  } else if (type === "html") {
    logo = <AiFillHtml5 size="16px" color="#E34C26" />;
  } else if (type === "json") {
    logo = <img src={Json_Logo} alt="Json Logo" className="w-[15px]" />;
  } else if (IMAGE_FILE.includes(type)) {
    if (file.thumbnail) {
      logo = (
        <img src={file.thumbnail} alt="Upload Image" className="w-[15px]" />
      );
    } else {
      logo = <FiImage />;
    }
  } else if (type === "py") {
    logo = <img src={Python} alt="Python file" className="w-[15px]" />;
  } else if (type === "txt" || type === "md") {
    logo = <img src={Info} alt="text file" className="w-[15px]" />;
  } else if (VIDEO_FILE.includes(type)) {
    if (file.thumbnail) {
      logo = (
        <img src={file.thumbnail} alt="Upload Image" className="w-[15px]" />
      );
    } else {
      logo = <GoVideo />;
    }
  }
  return logo;
};
