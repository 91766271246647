import React, { useEffect, useState, useRef } from 'react'
import axios from 'axios'
import { v4 as uuidv4 } from 'uuid'
import { useDropzone } from 'react-dropzone'
import SortableTree, { addNodeUnderParent, removeNodeAtPath } from 'react-sortable-tree'
import 'react-sortable-tree/style.css'
import { toast } from 'react-toastify'
import { useDispatch } from 'react-redux'
import dropProjectSlice from '../../store/dropProjectSlice'
import { setdropProject, cleardropProject } from '../../store/dropProjectSlice'

const Dropzone = ({ treeData, setTreeData }) => {
    const [mounted, setMounted] = useState(true)
    const [hovered, setHovered] = useState(false)
    const dispatch = useDispatch()

    const [uid, setUid] = useState(localStorage.getItem('uid'))
    useEffect(() => {
        if (!uid) {
            let tmpUid = uuidv4()
            setUid(tmpUid)
            localStorage.setItem('uid', tmpUid)
        }
    }, [])

    useEffect(() => {
        return () => {
            setMounted(false)
        }
    }, [])

    const handleDrop = (acceptedFiles) => {
        if (acceptedFiles.length > 0) {
            dispatch(setdropProject([acceptedFiles]))
        }
    }

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop: handleDrop,
    })

    const { isDragActive: isDragActiveInside } = useDropzone({
        onDragEnter: () => setHovered(true),
        onDragLeave: () => setHovered(false),
    })

    const isDragActiveOutside = isDragActive || isDragActiveInside

    return (
        <div
            {...getRootProps()}
            style={{
                height: '90px',
                width: '100%',
                margin: 'auto',
                marginBottom: '20px',
                borderRadius: '0.375rem',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                border: `2px dashed ${isDragActiveOutside ? '#888' : 'black'}`,
                backgroundColor: isDragActiveOutside || hovered ? '#eee' : '#e5e7eb',
                outline: 'none',
                userSelect: 'none',
                // visibility: `${isDragActiveOutside ? "visible" : "hidden"}`,
                // opacity: `${isDragActiveOutside ? "1" : "0"}`,
                // pointerEvents: `${isDragActiveOutside ? "auto" : "none"}`,
            }}
        >
            <p className='pl-4'>
                Drop <b>folder</b> or <b>zip</b> file to create project
            </p>
            <input {...getInputProps()} webkitdirectory='' />
            {/* <SortableTree treeData={treeData} onChange={setTreeData} /> */}
            <div
                className='max-w-xs bg-white border rounded-md shadow-lg dark:bg-gray-800 dark:border-gray-700'
                role='alert'
            ></div>
        </div>
    )
}

export default Dropzone
