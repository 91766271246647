import { createSlice } from "@reduxjs/toolkit";

const dropProjectSlice = createSlice({
  name: "dropProject",
  initialState: [],
  reducers: {
    setdropProject: (state, action) => {
      if (action.payload) {
        return [...state, action.payload[0]];
      }
      return state;
    },
    cleardropProject: (state) => {
      return [];
    },
  },
});

export const { setdropProject, cleardropProject } = dropProjectSlice.actions;
export default dropProjectSlice.reducer;
