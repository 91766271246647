import { createSlice } from "@reduxjs/toolkit";

const nodeSlice = createSlice({
  name: "Node",
  initialState: {},
  reducers: {
    setNode: (state, action) => {
      if (action.payload) {
        return action.payload;
      }
      return state;
    },
  },
});

export const { setNode } = nodeSlice.actions;
export default nodeSlice.reducer;
