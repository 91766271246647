import { createSlice } from "@reduxjs/toolkit";

const selectedURLSlice = createSlice({
  name: "selectedURL",
  initialState: "",
  reducers: {
    setSelectedURL: (state, action) => {
      return action.payload;
    },
    clearSelectedURL: (state) => {
      return "";
    },
  },
});


export const { setSelectedURL, clearSelectedURL} = selectedURLSlice.actions;
export default selectedURLSlice.reducer;