export function formatJSON(val = "") {
  try {
    let res = "";

    if (val !== "{}") {
      res = JSON.parse(JSON.stringify(val));
    }
    let temp = res;
    try {
      temp = JSON.parse(res);
      if (typeof temp === "string") {
        res = temp;
      }
    } catch (err) {}

    return res;
  } catch {
    const errorJson = {
      error: `error${val}`,
    };
    return JSON.stringify(errorJson, null, 2);
  }
}
