import { createSlice } from "@reduxjs/toolkit";

const selectedPathSlice = createSlice({
  name: "selectedPath",
  initialState: "/",
  reducers: {
    setSelectedPath: (state, action) => {
      return action.payload;
    },
    clearSelectedPath: (state) => {
      return null;
    },
  },
});


export const { setSelectedPath, clearSelectedPath} = selectedPathSlice.actions;
export default selectedPathSlice.reducer;