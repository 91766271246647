import React, { useEffect, useState, useRef, useCallback } from "react";
import { json, useNavigate, useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import ProjectSideBar from "../../pages/ProjectDetail/SideBar/ProjectSideBar";
import FileEditor from "../../pages/ProjectDetail/Editor/FileEditor";
import LoadingDialog from "../../components/Modal/LoadingDialog";
import { toast, ToastContainer } from "react-toastify";
import { useViewport } from "../../utils/CustomHook/useViewport";
import { getProjectData } from "../../api/projectAPI";

const Project = () => {
  let { id } = useParams();
  const [uid, setUid] = useState(localStorage.getItem("uid"));
  const [loading, setLoading] = useState(false);
  const [projectCode, setProjectCode] = useState("");
  const [activeFile, setActiveFile] = useState(null);
  const [contract, setContract] = useState(false);
  const [contentChanged, setContentChanged] = useState(false);
  const [previewMarkdown, setPreviewMarkdown] = useState("");
  const [createNewFile, setCreateNewFile] = useState(false);
  const [previewUrl, setPreviewUrl] = useState("");
  const [openExplorer, setOpenExplorer] = useState(false);

  useEffect(() => {
    if (!uid) {
      let tmpUid = uuidv4();
      setUid(tmpUid);
      localStorage.setItem("uid", tmpUid);
    }
  }, []);

  useEffect(() => {
    fetchFileData();
  }, [id]);

  const getFileTypeFromName = (name) => {
    if (!name) return "";
    let splits = name.split(".");
    if (splits.length > 1) return splits[splits.length - 1];
    return "";
  };

  //New
  const fetchFileData = async () => {
    if (!id || id.length <= 8) return;
    setLoading(true);
    try {
      const res = await getProjectData(id)
      setProjectCode(res.name);

      let projectChildren = [];
      if (res.children && res.children.length) {
        projectChildren = await Promise.all(
          res.children.map(async (file) => {
            if (file.isDir) {
              return {
                name: file.name,
                path: file.path,
                isDir: file.isDir,
              };
            } else {
              return {
                fileLink: file.fileLink,
                thumbnail: file.thumbnail,
                name: file.name,
                path: file.path,
                isDir: file.isDir,
                filename: file.name,
                type: getFileTypeFromName(file.name),
              };
            }
          })
        );
      }
    } catch (err) {
      console.log("error fetching project data", err);
    }
    setLoading(false);
  };

  const { width } = useViewport();

  return (
    <div className="h-full w-full flex">
      <ToastContainer
        autoClose={2500}
        draggablePercent={60}
        style={{ width: "250px", height: "100px", fontSize: "13px" }}
      />
      <div
        className={`bg-slate-300 h-full ${
          openExplorer
            ? "hidden"
            : "max-w-[100px] lg:min-w-[256px] sm:max-w-[150px]"
        }`}
      >
        <ProjectSideBar
          contentChanged={contentChanged}
          setPreviewFile={(file) => {
            setPreviewUrl(`${file}?abc=${Date.now()}`);
            setActiveFile(file);
          }}
          setEditFile={(file) => {
            setActiveFile(file);
          }}
          setActiveFile={setActiveFile}
        />
      </div>
      {/* Editor */}
      <div
        className={`grow h-full overflow-y-scroll ${contract ? "hidden" : ""}`}
      >
        {(activeFile || previewUrl) && (
          <FileEditor
            filePath={activeFile}
            previewUrl={previewUrl}
            previewMarkdown={previewMarkdown}
            setContentChanged={setContentChanged}
            contentChanged={contentChanged}
            createNewFile={createNewFile}
            setContract={setContract}
            onContentSaved={() => {
              if (!previewUrl) return;
              let previewIframe = document.getElementById("previewIframe");
              if (previewIframe) {
                previewIframe.src = "";
                setTimeout(() => (previewIframe.src = previewUrl), 500);
              }
            }}
            onOpenExplorer={(state) => {
              setOpenExplorer(state);
            }}
          />
        )}
      </div>

      {/* Loading dialog */}
      {loading && <LoadingDialog />}
    </div>
  );
};

export default Project;
