import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'
import { cleardropProject } from '../../../store/dropProjectSlice'
import { clearSelectedIndex } from '../../../store/selectedIndexSlice'
import { Alert } from '@mui/material'
import Dropzone from '../../DnD/Dropzone'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { noJSContent, withJSContent } from '../../../utils/Template/widgetContent'
import useCheckUser from '../../../utils/CustomHook/useCheckUser'
import { createNewProject } from '../../../api/projectAPI'
import { uploadFile } from '../../../api/fileAPI'
import { STATIC_HOST } from '../../../api/config'
import { TbTrash } from 'react-icons/tb'
import { loadAsync } from 'jszip'

export default function CreateProjectDialog({
    allProjectsName,
    onProjectCreatedSuccess,
    setLoading,
    uid,
    openProjectModal,
    onCloseCreateProjectModal,
}) {
    const theme = useTheme()
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'))
    const dispatch = useDispatch()
    const dropProject = useSelector((state) => state.dropProject)
    const [duplicateProject, setDuplicateProject] = useState('')
    const [projectID, setProjectID] = useState('')
    const [project, setProject] = useState('')
    const [newProjectName, setNewProjectName] = useState('')
    const [files, setFiles] = useState()

    const uploadingZipFile = useMemo(() => {
        if (dropProject.length > 0) {
            return dropProject[0][0]?.path?.endsWith('.zip')
        }
    }, [dropProject])

    const [onEnter, setOnEnter] = React.useState(false)

    let navigate = useNavigate()

    const isLoggedIn = useCheckUser()

    const checkProject = (file) => {
        if (file.path.includes('/')) {
            return file.path.split('/')[1]
        } else if (file.path.endsWith('.zip')) {
            return (file.name || file.path)?.replace(/\.[^/.]+$/, '')
        } else return ''
    }

    //create project from input
    const handleNewProjectName = (e) => {
        if (e.target.value && allProjectsName.indexOf(e.target.value) > -1) {
            setDuplicateProject(e.target.value)
        } else {
            setNewProjectName(e.target.value)
            setDuplicateProject('')
        }
    }

    const selectedIndex = useSelector((state) => state.selectedIndex)

    //new
    const handleCreateNewProject = async () => {
        try {
            setLoading(true)
            if (dropProject.length > 0) {
                const response = await createNewProject(newProjectName, uid, visibility)
                const projectID = response.name
                setProject(response.originalname)

                await Promise.all(
                    dropProject[0]?.map(async (file) => {
                        const formData = new FormData()
                        formData.append('type', file.name.substr(file.name.lastIndexOf('.') + 1))
                        formData.append('uid', uid)
                        formData.append('project', projectID)
                        formData.append('path', file.path)
                        formData.append('uploaded_file', file)
                        await uploadFile(projectID, formData, uploadingZipFile ? { extractFile: true } : undefined)
                    })
                )
                navigate(`/project/${response.name}`, {
                    state: {
                        projectName: newProjectName,
                    },
                })
                toast.success('Created project successfully')
                dispatch(cleardropProject())
            } else {
                const response = await createNewProject(newProjectName, uid, visibility)
                setNewProjectName(response.originalname)
                setProjectID(response.name)
                //Create 2 files if newWidget be selected
                if (selectedIndex === 1) {
                    for (let i = 0; i < 2; i++) {
                        try {
                            let filename, content, path
                            if (i === 0) {
                                filename = 'SetGetNoJS.html'
                                content = noJSContent
                                path = '/SetGetNoJS.html'
                            } else if (i === 1) {
                                filename = 'SetGetWithJS.html'
                                content = withJSContent
                                path = '/SetGetWithJS.html'
                            }

                            await axios.post(`${STATIC_HOST}project/${response.name}/file`, {
                                filename,
                                content,
                                path,
                            })
                        } catch (err) {
                            console.log(err)
                        }
                    }
                }
                navigate(`/project/${response.name}`, {
                    state: {
                        newProjectName: newProjectName,
                    },
                })
                toast.success('Created project successfully')
                dispatch(cleardropProject())
                dispatch(clearSelectedIndex())

                if (onProjectCreatedSuccess) {
                    onProjectCreatedSuccess(newProjectName)
                }
            }
        } catch (error) {
            console.log(error)
            dispatch(cleardropProject())
        } finally {
            setLoading(false)
        }
    }

    // Close the project modal
    const handleCloseProject = () => {
        setNewProjectName('') // Reset the input value
        setDuplicateProject('')
        setVisibility('public')
        dispatch(cleardropProject())
        dispatch(clearSelectedIndex())
        onCloseCreateProjectModal()
    }

    const checkAndSetProjectNameFromUpload = useCallback(
        async (dropItems) => {
            var projectName
            dropItems[0]?.forEach((x) => {
                projectName = checkProject(x)
            })

            setNewProjectName(projectName)
            if (!projectName) {
                toast.error('Please select a folder or a zip file')
                dispatch(cleardropProject())
                return false
            }
            if (allProjectsName?.includes(projectName)) {
                setDuplicateProject(projectName)
            }
            return true
        },
        [allProjectsName, dispatch]
    )

    const listFiles = useCallback(
        async (dropItems) => {
            const isValid = checkAndSetProjectNameFromUpload(dropItems)
            if (!isValid) return null
            if (dropItems.length > 0) {
                // If the dropped item is a zip file, show the files inside the zip file
                if (dropItems[0][0].path?.endsWith('.zip')) {
                    if (dropItems[0][1]) {
                        toast.error('Please select only one zip file')
                        dispatch(cleardropProject())
                        setNewProjectName('')
                        return null
                    }

                    try {
                        const zip = await loadAsync(dropItems[0][0])
                        return Object.entries(zip.files).reduce((acc, [path, file]) => {
                            // Show files only, ignore directories and __MACOSX files
                            if (!path.endsWith('/') && !path.startsWith('__MACOSX'))
                                acc.push(
                                    <li className='p-1' key={path}>
                                        <span className='font-semibold'>{path}</span> -{' '}
                                        {file?._data?.compressedSize ?? 0} bytes
                                    </li>
                                )
                            return acc
                        }, [])
                    } catch (error) {
                        console.log('Error reading zip file:', error)
                        toast.error('Invalid zip file')
                        dispatch(cleardropProject())
                        setNewProjectName('')
                        return null
                    }
                } else if (dropItems[0][0].path?.includes('/')) {
                    return dropItems[0]?.map((file) => (
                        <li key={file.path} className='p-1'>
                            <span className='font-semibold'>{file.path.split('/')[2]}</span> - {file.size} bytes
                        </li>
                    ))
                }
            }
            return null
        },
        [checkAndSetProjectNameFromUpload, dispatch]
    )

    // Show the files that are being uploaded
    useEffect(() => {
        if (dropProject.length > 0) {
            listFiles(dropProject).then((files) => setFiles(files))
        } else {
            setFiles(null)
        }
    }, [dropProject, listFiles])

    useEffect(() => {
        console.log('current files', files)
    }, [files])

    const [visibility, setVisibility] = useState('public')

    const RadioInput = ({ label, value, checked, setter }) => {
        return (
            <label>
                <input type='radio' checked={checked == value} onChange={() => setter(value)} />
                <span className='ml-2'>{label}</span>
            </label>
        )
    }

    const handleClearDropProject = () => {
        dispatch(cleardropProject())
        setNewProjectName('')
    }

    return (
        <div>
            <Dialog
                fullScreen={fullScreen}
                open={openProjectModal}
                onClose={handleCloseProject}
                aria-labelledby='responsive-dialog-title'
            >
                <DialogTitle id='responsive-dialog-title' className='font-bold text-gray-800 flex justify-between'>
                    Create new project
                    <button
                        onClick={handleCloseProject}
                        type='button'
                        className='hs-dropdown-toggle inline-flex flex-shrink-0 justify-center items-center h-8 w-8 rounded-md text-gray-500 hover:text-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-offset-2 focus:ring-offset-white transition-all text-sm dark:focus:ring-gray-700 dark:focus:ring-offset-gray-800'
                    >
                        <span className='sr-only'>Close</span>
                        <svg
                            className='w-3.5 h-3.5'
                            width='8'
                            height='8'
                            viewBox='0 0 8 8'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                        >
                            <path
                                d='M0.258206 1.00652C0.351976 0.912791 0.479126 0.860131 0.611706 0.860131C0.744296 0.860131 0.871447 0.912791 0.965207 1.00652L3.61171 3.65302L6.25822 1.00652C6.30432 0.958771 6.35952 0.920671 6.42052 0.894471C6.48152 0.868271 6.54712 0.854471 6.61352 0.853901C6.67992 0.853321 6.74572 0.865971 6.80722 0.891111C6.86862 0.916251 6.92442 0.953381 6.97142 1.00032C7.01832 1.04727 7.05552 1.1031 7.08062 1.16454C7.10572 1.22599 7.11842 1.29183 7.11782 1.35822C7.11722 1.42461 7.10342 1.49022 7.07722 1.55122C7.05102 1.61222 7.01292 1.6674 6.96522 1.71352L4.31871 4.36002L6.96522 7.00648C7.05632 7.10078 7.10672 7.22708 7.10552 7.35818C7.10442 7.48928 7.05182 7.61468 6.95912 7.70738C6.86642 7.80018 6.74102 7.85268 6.60992 7.85388C6.47882 7.85498 6.35252 7.80458 6.25822 7.71348L3.61171 5.06702L0.965207 7.71348C0.870907 7.80458 0.744606 7.85498 0.613506 7.85388C0.482406 7.85268 0.357007 7.80018 0.264297 7.70738C0.171597 7.61468 0.119017 7.48928 0.117877 7.35818C0.116737 7.22708 0.167126 7.10078 0.258206 7.00648L2.90471 4.36002L0.258206 1.71352C0.164476 1.61976 0.111816 1.4926 0.111816 1.36002C0.111816 1.22744 0.164476 1.10028 0.258206 1.00652Z'
                                fill='currentColor'
                            />
                        </svg>
                    </button>
                </DialogTitle>
                <DialogContent className='!p-4 !border-b'>
                    <div className='overflow-y-auto pb-4'>
                        <label htmlFor='input-label' className='block text-sm mb-2 font-semibold'>
                            Project Name
                        </label>
                        <input
                            type='text'
                            required
                            id='input-label'
                            className='border border-[#8080803] py-3 px-4 block w-full border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-blue-500 '
                            placeholder='Enter your project name'
                            autoFocus
                            onChange={handleNewProjectName}
                            defaultValue={newProjectName ? newProjectName : ''}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    if (!duplicateProject && newProjectName !== '') {
                                        setOnEnter(true)
                                        handleCloseProject()
                                        handleCreateNewProject()
                                    }
                                }
                            }}
                        />

                        {duplicateProject.length > 0 && (
                            <Alert severity='error' sx={{ padding: '0 16px', maxWidth: '478px', marginTop: '10px' }}>
                                <span className='font-bold'>{duplicateProject}</span> is already existed. Please choose
                                a different name.
                            </Alert>
                        )}
                    </div>
                    {!files && (
                        <div className={`overflow-y-auto`}>
                            <p className='text-sm font-medium mb-2'>Or drop your new project here</p>
                            <Dropzone />
                        </div>
                    )}

                    {files && (
                        <div className='pb-4'>
                            <div className='items-center justify-between flex'>
                                <p className='font-semibold text-sm'>
                                    {uploadingZipFile ? 'Extracted Files' : 'Uploaded Files'}
                                </p>
                                <button
                                    onClick={handleClearDropProject}
                                    className='border-2 hover:bg-red-100 border-red-500 transition duration-150 rounded-md flex items-center gap-1 font-medium text-gray-800 px-2 py-1 text-sm'
                                >
                                    <span className='text-red-500'>Clear Upload</span>
                                    <TbTrash className='text-base text-red-500' />
                                </button>
                            </div>
                            <div className='w-full max-h-[6rem] overflow-y-auto bg-[#eee] list-decimal text-sm border-2 border-dashed px-6 py-1 border-[#888] my-2'>
                                {files}
                            </div>
                        </div>
                    )}

                    {localStorage.getItem('token') && (
                        <div className='flex items-center gap-6'>
                            <RadioInput label='Public' value='public' checked={visibility} setter={setVisibility} />
                            <RadioInput label='Private' value='private' checked={visibility} setter={setVisibility} />
                        </div>
                    )}
                </DialogContent>
                <DialogActions
                    sx={{
                        padding: '12px 16px',
                    }}
                >
                    <Button
                        onClick={handleCloseProject}
                        sx={{
                            bgcolor: 'white',
                            color: 'rgb(55 65 81)',
                            padding: '12px 16px',
                            border: '1px solid #80808033',
                            '&:hover': {
                                backgroundColor: 'rgb(249 250 251)',
                            },
                            borderRadius: '0.375rem',
                        }}
                    >
                        Close
                    </Button>
                    <Button
                        onClick={handleCreateNewProject}
                        disabled={duplicateProject || newProjectName === ''}
                        sx={{
                            padding: '12px 16px',
                            backgroundColor: onEnter === true ? 'rgb(37 99 235)' : 'rgb(59 130 246)',
                            color: 'white',
                            '&:hover': {
                                backgroundColor: 'rgb(37 99 235)',
                            },
                            '&:disabled': {
                                backgroundColor: 'rgb(147 197 253) !important',
                                color: 'white !important',
                            },
                            borderRadius: '0.375rem',
                        }}
                    >
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}
